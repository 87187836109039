<template>
  <div class="game-container">
    <RecognitionPinyinBasketBallGame
      :answerList="answerList"
      :optionList="optionList"
      :audioList="audioList"
      :totalStarNumber="4"
    />
  </div>
</template>

<script>
import RecognitionPinyinBasketBallGame from "@/components/Course/GamePage/RecognitionPinyinBasketBallGame";
export default {
  data() {
    return {
      lessonId: 7,
      answerList: [
        {
          id: 1,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
        {
          id: 1,
          sound: "",
          showObj: true,
        },
      ],

      optionList: [],
      audioList: [
        require("@/assets/audio/L1/2-Pinyin/b.mp3"),
        require("@/assets/audio/L1/2-Pinyin/p.mp3"),
        require("@/assets/audio/L1/2-Pinyin/p.mp3"),
        require("@/assets/audio/L1/2-Pinyin/b.mp3"),
      ],
    };
  },
  mounted() {
    let newArr = [];
    for (let i = 0; i < 4; i++) {
      const ele = [
        {
          id: 1,
          bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-02/basketball-b.svg"),
          showObj: true,
        },
        {
          id: 2,
          bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-02/basketball-p.svg"),
          showObj: true,
        },
        {
          id: 3,
          bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-02/basketball-a.svg"),
          showObj: true,
        },
        {
          id: 4,
          bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-02/basketball-e.svg"),
          showObj: true,
        },
      ];
      newArr.push(ele);
    }
    //  return newArr
    this.optionList = newArr;
    console.log(this.optionList, "qqqqqqqqqqqqq");
  },
  components: {
    RecognitionPinyinBasketBallGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-15.mp3"),
      },
      timeStampList: [8.8, 12.8, 17.3, 21.7, 35.1, 43.3, 53],
      singList: {
        lyricsArr: [
          {
            id: 1,
            chineseWords: "b, p, m, f",
          },
          {
            id: 2,
            chineseWords: "bb, bbb, bàba（爸爸）",
          },
          {
            id: 3,
            chineseWords: "pp, ppp, pópo（婆婆）",
          },
          {
            id: 4,
            chineseWords: "mm, mmm, māma（妈妈）",
          },
          {
            id: 5,
            chineseWords: "mm, mmm, ff, fff",
          },
          {
            id: 6,
            chineseWords: "m-ó-mó,f-ǎ-fǎ,mófǎ（魔法）",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>

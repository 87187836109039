<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList" />
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-b.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-p.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-m.svg"),
            showObj: true,
          },
          {
            id: 4,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-f.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-b.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-p.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-m.svg"),
            showObj: true,
          },
          {
            id: 4,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-f.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-b.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-p.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-m.svg"),
            showObj: true,
          },
          {
            id: 4,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-f.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-b.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-p.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-m.svg"),
            showObj: true,
          },
          {
            id: 4,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-f.svg"),
            showObj: true,
          },
        ],
      ],

      answerList: [
        {
          id: 1,
          type: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/ba4.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzled-b-white.svg"),
          secondImg : require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-a4.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-character-ba.svg"),
        },
        {
          id: 3,
          type: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/ma1.mp3"),
          isCorrect: false,
         firstImg : require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzled-m-white.svg"),
          secondImg : require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-a1.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-character-ma.svg"),
        },
        {
          id: 2,
          type: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/pa2.mp3"),
          isCorrect: false,
         firstImg : require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzled-p-white.svg"),
          secondImg : require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-a2.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-character-pa.svg"),
        },
        {
          id: 4,
          type: 1,

          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/fu3.mp3"),
          isCorrect: false,
         firstImg : require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzled-f-white.svg"),
          secondImg : require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-u3.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-16/puzzle-character-fu.svg"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>

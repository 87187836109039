// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/img/19-Book-review/lesson-15-review-01.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".spaeaking-page-two-container .spaeking-page-two-box[data-v-146acf56] {\n  width: 100%;\n  height: 100%;\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n  -moz-background-size: 100% 100%;\n  background-color: #f5f9ff;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  border-radius: 58px;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.spaeaking-page-two-container .spaeking-page-two-box .spaeaking-page-two-content[data-v-146acf56] {\n    width: 90%;\n}\n@media screen and (max-width: 1500px) {\n.spaeaking-page-two-container .spaeking-page-two-box .spaeaking-page-two-content[data-v-146acf56] {\n        width: 75%;\n}\n}\n@media screen and (max-width: 1300px) {\n.spaeaking-page-two-container .spaeking-page-two-box .spaeaking-page-two-content[data-v-146acf56] {\n        width: 95%;\n}\n}\n.spaeaking-page-two-container .spaeking-page-two-box .spaeaking-page-two-content img[data-v-146acf56] {\n      width: 100%;\n}\n", ""]);
// Exports
module.exports = exports;
